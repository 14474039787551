/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Layout from "./layout"
import Title from "./title"
import Listing from "./listing"
import List from "./list"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import useSiteMetadata from "../hooks/use-site-metadata"
import replaceSlashes from "../utils/replaceSlashes"
import { visuallyHidden } from "../styles/utils"
import emailStyle from "../styles/emailStyle"
// @ts-ignore
import Hero from "../texts/hero"
// @ts-ignore
import Bottom from "../texts/bottom"

import ConvertKit from 'convertkit-react'

const MY_FORM_ID = 2581926

let options = {
    settings: {
        powered_by: {
            show: false
        }
    }
}

const convertkitConfig = {
  formId: 2581926,
  template: 'mills',
  emailPlaceholder: 'Enter an email address',
  submitText: 'Notify me!',
  hideName: true,

    powered_by: {
        show: false
    
}
}


function SubscribeViaEmail() {
  return (

    <ConvertKit {...convertkitConfig} />
  )
}

type PostsProps = {
  posts: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
  }[]
  [key: string]: any
}

const Homepage = ({ posts }: PostsProps) => {
  const { basePath, blogPath } = useMinimalBlogConfig()
  const { siteTitle } = useSiteMetadata()

  return (
    <Layout>

      <p>
        I'm a machine learning engineer with <a href="https://aws.amazon.com/" target="_blank">Amazon Web Services</a> (AWS) working on the SageMaker Long-Term Science team. 
      </p>

      <p>
        My current work is focused on maintaining my team's three open-source libraries for machine learning: 
        <ul>
          <li><a href="https://github.com/awslabs/syne-tune" target="_blank">Syne Tune</a> (hyperparameter and algorithm tuning)</li>
          <li><a href="https://github.com/awslabs/Renate" target="_blank">Renate</a> (continual learning)</li>
          <li><a href="https://github.com/awslabs/fortuna" target="_blank">Fortuna</a> (uncertainty quantification and calibration)</li>
        </ul>
      </p>

      <p>
        I joined Amazon in 2019, and my first role was working a software engineer in the Human-in-the-Loop organization on the <a href="https://aws.amazon.com/sagemaker/data-labeling/" target="_blank">Amazon SageMaker Ground Truth</a> data labeling service. In addition to shipping <a href ="https://docs.aws.amazon.com/sagemaker/latest/dg/sms-point-cloud.html" target="_blank">new LiDAR labeling apps</a> and making platform improvements, I also worked directly with customers on their data labeling projects as part of <a href="https://docs.aws.amazon.com/sagemaker/latest/dg/gtp.html" target="_blank">Ground Truth Plus</a>, AWS's turnkey data labeling service. 
      </p>

      <p>
        Before joining AWS I interned at <a href="https://smartsheet.com/" target="_blank">Smartsheet</a>, <a href="https://newrelic.com/" target="_blank">New Relic</a>, and the <a href="https://graal.dibris.unige.it/" target="_blank">Genoa Robotics and Automation Laboratory</a>. I graduated from Dartmouth College in 2019 with a major in Computer Science and a minor in Government. While at Dartmouth I worked in the <a href="https://dali.dartmouth.edu/" target="_blank">DALI Lab</a> as a developer, taught an Italian grammar class, and was active in the Dartmouth Mountaineering Club.
      </p>

      <p>
        My interests include building (and writing about) tools, systems, and interfaces of all kinds.
      </p>

      <p>
      You can <a href="https://twitter.com/weskendrick" target="_blank">find me on twitter</a> or email me at wes [ at ] this URL.
      </p>

      {/* <h1 sx={visuallyHidden}>{siteTitle}</h1> */}

      {/* <Listing posts={posts} showTags={false} /> */}

      {/* <List sx={{ variant: `section_bottom` }}>
        <Bottom />
      </List> */}
      {/* <SubscribeViaEmail /> */}
    </Layout>
  )
}

export default Homepage
